import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonReset } from '../ui';
import { PrismicRichText } from '@prismicio/react';
import { motion } from 'framer-motion';
import TextToSpeechButton from 'components/TextToSpeechButton';

const Card = styled(motion.div)`
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing()};
  background-color: ${({ theme }) => theme.card.background};
  padding-right: 80px;
  position: relative;
  overflow: hidden;
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${({ theme }) => theme.textLight};
  font-size: 1.5rem;
  font-weight: 600;
`;

const CardContent = styled.div`
  color: ${({ theme }) => theme.textLight};
  > *:last-child {
    margin-bottom: 0;
  }
`;

const Button = styled(ButtonReset)`
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.secondary};
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const WrapperIcon = styled.div`
  position: relative;
`;

const Horizontal = styled(motion.div)`
  position: absolute;
  background-color: #fff;
  width: 20px;
  height: 4px;
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -2px;
`;

const Vertical = styled(motion.div)`
  position: absolute;
  background-color: #fff;
  width: 4px;
  height: 20px;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: -10px;
`;

const Faq = ({ faq, selectedFaq, onSelectFaq, variant, id }) => {
  const [cardHeight, setCardHeight] = useState();
  const expanded = selectedFaq === id ? true : false;
  const titleRef = useRef();

  const handleClickExpandedButton = () => {
    if (selectedFaq === id) {
      onSelectFaq(null);
    } else {
      onSelectFaq(id);
    }
  };

  useEffect(() => {
    setCardHeight(titleRef.current.offsetHeight + 38 + 32);
  }, [titleRef]);

  return (
    <Card
      expanded={expanded}
      initial={expanded}
      animate={{
        height: expanded ? '100%' : cardHeight,
      }}
      transition={{ ease: 'easeOut', duration: 0.25 }}
    >
      <div id={id}>
        <CardTitle ref={titleRef}>{faq?.question}</CardTitle>
        {variant !== 'pro' && (
          <TextToSpeechButton
            id={id}
            index={id}
            onClick={() => onSelectFaq(id)}
            shouldShowOverlay={!expanded}
          />
        )}

        <CardContent>
          <PrismicRichText field={faq.answer?.richText} />
        </CardContent>
      </div>

      <Button onClick={handleClickExpandedButton}>
        <WrapperIcon>
          <Horizontal
            initial={expanded}
            transition={{ ease: 'easeOut', duration: 0.25 }}
            animate={{
              transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)',
              opacity: expanded ? 0 : 1,
            }}
          />
          <Vertical
            initial={expanded}
            transition={{ ease: 'easeOut', duration: 0.25 }}
            animate={{
              transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)',
            }}
          />
        </WrapperIcon>
      </Button>
    </Card>
  );
};

export default Faq;
